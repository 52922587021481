@import './foundation-icons/foundation-icons.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans",sans-serif;
  font-size: 62.5%; // 1 rem = 10px;
}

a.external_link {
  text-decoration: none;

  &:after {
    padding-left: 6px;
    content: "\f08e";
    font-family: "FontAwesome";
  }
}

button {
  &:focus, &:hover {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }
}

ol, ul {
  list-style-position: inside;
}

li {
  line-height: initial;
}

.visually-hidden:not(:focus):not(:focus-within):not(:active) {
  color: transparent;
  background: transparent;
  border: transparent;
  text-decoration-color: transparent;
  box-shadow: none;
}

footer a {
  color: #015149;
  text-decoration: underline;
}

footer a:hover {
  text-decoration: none;
}

span.small-text {
  font-size: 0.75rem;
}

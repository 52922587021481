@import "../../global-styles/mixins";
@import '../../global-styles/_variables';

.EndPage {
  @include page;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 32px 16px;

  &.App-service {
    max-width: 1100px;
  }

  &__logo {
    display: none;
  }

  &__header {
    width: 100%;
    text-align: center;
  }

  &__title {
    font-size: 32px;

    &.App-service {
      font-size: 40px;
    }
  }

  &__notice {
    font-size: 18px;
    font-weight: bold;
    padding: 16px 0;

    &.App-service {
      font-size: 30px;
    }
  }

  &__info {
    font-weight: 300;
    text-align: left;
    width: 100%;
    padding: 8px 0;
  }

  &__subHeader {
    font-size: 24px;
    padding-bottom: 8px;

    &.App-service {
      font-size: 28px;
    }
  }

  &__content {
    font-size: 18px;
    text-align: left;
    padding: 8px 0;

    &.App-service {
      font-size: 20px;
    }

    & ul {
      padding-inline-start: 40px;
      list-style: initial;
      line-height: 16px;
    }

    a {
      color: $font-green;
      text-decoration: underline;
    }

  }

  &__email {
    padding: 16px;

    & h2 {
      font-size: 18px;
      margin-bottom: 16px;
    }

    & div {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
    }

    & input {
      height: 48px;
      min-width: 160px;
      margin: 12px;
    }

    & button {
      height: 48px;
      padding: 12px 24px;
      margin: 12px;
      max-width: 120px;

      & div {
        font-size: 20px;
      }
    }

    & > span {
      display: block;
      font-size: 16px;
      padding: 8px;
      border: solid 1px;
      border-radius: 4px;
      margin-top: 24px;

      &.Endpage__emailSuccess {
        border-color: $color-primary-alert-success;
        background-color: $color-secondary-alert-success;
        color: $color-text-alert-success;
      }

      &.Endpage__emailError {
        border-color: $color-primary-error;
        background-color: $color-secondary-error;
        color: $color-text-error;
      }
    }
  }

  &__controls {
    padding: 16px 0 40px;

    &.App-service {
      display: flex;
      margin-bottom: 64px;
    }
  }

  @media print {
    &__logo {
      display: initial;
    }

    &__header {
      display: none;
    }

    &__notice.App-service {
      font-size: 12pt;
    }

    &__subHeader.App-service {
      font-size: 14pt;
    }

    &__content.App-service {
      font-size: 12pt;
    }

    &__controls.App-service {
      display: none;
    }
  }
}
